#nav-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background: rgba($lightgrey, 0.9);
	transition: transform 0.5s $easeInOutQuart, background 0.5s ease;
	transform: translateY(0) translateZ(9999px);
	height: 56px;
	@include susy-breakpoint($small) {
		height: 88px; }
	@include susy-breakpoint($medium) {
		height: 98px; }

	&.no-bg {
		background: rgba($lightgrey, 0); } }

body:not(.touch) {
	#nav-wrapper.hide {
		transform: translateY(-100px) translateZ(9999px); } }

.brand {
	position: absolute;
	top: 1.1rem;
	left: 1rem;
	font-size: 1.2rem;
	font-family: 'Arial';
	font-weight: bold;
	z-index: 99999;

	@include susy-breakpoint($small) {
		top: 2rem;
		left: 2rem; } }

.nav-trigger {
	position: absolute;
	top: 0.75rem;
	right: 1rem;
	z-index: 99999;
	font-size: 2.1rem;

	@include susy-breakpoint($small) {
		top: 1.75rem;
		right: 2rem; }

	@include susy-breakpoint($mid) {
		display: none; } }

html.active,
body.active {
	overflow-y: hidden;
	height: 0; }

ul.nav {
	position: absolute;
	width: 100%;
	min-height: 100%;
	height: 100%;
	padding: 3rem 1rem 1rem;
	z-index: 9999;
	background: $lightgrey;
	display: none;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;

	@include susy-breakpoint($small) {
		padding: 5rem 2rem 1rem; }

	&.active {
		display: block;
		height: 100vh; }

	@include susy-breakpoint($mid) {
		background: none;
		padding: 0;
		top: 2rem;
		left: auto;
		width: auto;
		min-height: auto;
		height: auto;
		overflow-y: visible;
		right: 2rem;
		display: block; }

	a {
		font-family: 'Arial';
		cursor: pointer;
		font-size: 3rem;
		font-weight: normal;
		@include susy-breakpoint($mid) {
			font-family: 'Arial';
			font-weight: bold;
			font-size: 1.2rem; }

		&.anchor {
			cursor: default; } }

	li {
		display: block;
		padding: 0rem;
		position: relative;
		@include susy-breakpoint($mid) {
			display: inline-block;
			padding: 0 0.75rem; } }

	& > li.has-sub > ul.sub {
		top: 22px;
		@include susy-breakpoint($medium) {
			top: 24px; } }

	li ul.sub {
		display: none;
		@include rkt('');

		ul.pop {
			@include rkt(''); }

		@include susy-breakpoint($mid) {
			@include rkt('match-from');
			position: absolute;
			top: 1.5rem;
			left: 0;

			// Third level
			ul.pop {
				visibility: hidden;
				position: fixed;
				background: $white;
				padding: 1rem;
				left: 50%;
				width: 600px;
				min-height: 260px;
				top: 50vh;
				transform: translate(-50%, -50%);
				// +rkt('vcenter hcenter')

				li {
					@include susy-breakpoint($mid) {
						padding: 0 0.5rem; } }

				a {
					color: $black;
					font-family: 'Arial';
					font-size: 2rem;
					font-weight: normal; } }

			&.keep-open li.open ul {
				visibility: visible; } } }

	@include susy-breakpoint($mid) {
		> li > ul.keep-open,
		> li:hover > ul {
			display: block; }

		// li ul li:hover ul
 }		// 	display: block

	li ul li {
		display: block; }

	li ul li a {
		@include susy-breakpoint($mid) {
			color: $grey; }

		&:hover {
			color: $black; } }

	li.open {
		&>ul {
			display: block; }
		&>a {
			color: $grey; } }

	li.closed > a {
		color: rgba($grey,0.5); }


	li.search-trigger {
		img {
			max-width: 2.5rem;
			vertical-align: middle;
			display: block;
			cursor: pointer;

			&.search-mobile {
				padding-top: 1rem;
				@include susy-breakpoint($mid) {
						display: none; } }

			&.search-desktop {
				display: none;
				@include susy-breakpoint($mid) {
					display: inline-block;
					max-width: 16px;
					padding-bottom: 3px; } } } } }


.sub-nav-closer {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
	z-index: 9998;
	cursor: pointer;

	&.active {
		display: block; } }


@include susy-breakpoint($mid) {
	#barba-wrapper.blur {
		opacity: 0.5;
		filter: blur(3px); } }


ul.nav .contact-pop {
	visibility: hidden;
	position: fixed;
	background: $white;
	padding: 1rem;
	padding-top: 4rem;
	left: 50%;
	max-width: 800px;
	width: 100%;
	height: 100vh;
	top: 50vh;
	overflow-y: auto;
	z-index: 2;
	transform: translate(-50%, -50%);

	@include susy-breakpoint($small) {
		height: 350px;
		padding-top: 0;
		padding: 1.5rem; }

	.contact-pop-inner {
		@include container(100%); }

	.contact-pop-left {
		@include susy-breakpoint($small) {
			@include span(1.1 of 3 first); } }

	.contact-pop-right {
		@include susy-breakpoint($small) {
			@include span(1.9 of 3 last); } }


	a {
		font-size: 1.2rem;
		font-family: 'Arial'; }

	input {
		border: none;
		border-bottom: 1px solid $black;
		margin: 1rem 0;
		font-family: 'Arial';
		padding: 0;
		display: block;
		width: 100%;

		&::placeholder {
			color: $black; } }

	button {
		font-weight: bold;
		background: none;
		border: none;
		padding: 0;
		font-family: 'Arial';
		cursor: pointer; }

	.social-links {
		margin: 0.5rem 0;
		padding: 0;

		@include susy-breakpoint($small) {
			position: absolute;
			bottom: 1.5rem;
			left: 1.5rem; }

		li {
			display: inline-block;
			margin-right: 0.15rem;
			padding: 0; }

		a {
			display: block;
			background: $black;
			color: $white !important;
			width: 2rem;
			height: 2rem;
			line-height: 2.1rem;
			text-align: center;
			border-radius: 50%;
			font-size: 1.2rem;

			&:hover {
				color: $black; } } } }

ul.nav > li.has-sub.open > .contact-pop {
	visibility: visible; }

ul.nav > li.has-sub.open > a {
	color: $black; }


li.index-button {
	cursor: pointer;
	position: fixed;
	right: 70px;
	top: 20px;
	height: 17px;
	width: 17px;
	padding: 0;
	z-index: 99;
	list-style-type: none;

	@include susy-breakpoint($small) {
		right: 100px;
		top: 36px; }

	@include susy-breakpoint($mid) {
		right: 500px;
		top: 34px; }

	@include susy-breakpoint($medium) {
		right: 545px;
		top: 38px; }

	label {
		position: absolute;
		top: 1px;
		right: 35px;
		opacity: 0;
		transition: all 0.3s ease;
		// display: none
		font-family: 'Arial';
		@include susy-breakpoint($mid) {
			display: block; }

		@include susy-breakpoint($medium) {
			top: -2px; } }

	&:not(.freeze).active:hover {
		.grid-label {
			opacity: 1; } }

	&:not(.active):not(.freeze):hover {
		.index-label {
			opacity: 1; } }

	span {
		width: 6px;
		height: 6px;
		background: $black;
		position: absolute;
		transition: all 0.4s ease 0.2s;

		&:nth-of-type(1) {
			top: 0;
			left: 0; }

		&:nth-of-type(2) {
			top: 0;
			right: 0; }

		&:nth-of-type(3) {
			bottom: 0;
			left: 0; }

		&:nth-of-type(4) {
			bottom: 0;
			right: 0; } }

	&.active {
		span {
			&:nth-of-type(1) {
				top: 10px;
				left: 8px; }

			&:nth-of-type(2) {
				top: 13px;
				right: 11px; }

			&:nth-of-type(3) {
				bottom: 10px;
				left: 12px; }

			&:nth-of-type(4) {
				bottom: 13px;
				right: 11px; } } } }

//variables

// dont use bourbon box-sizing mixin with suzy
$susy: ( use-custom: ( box-sizing: false ));

$gutter: (gutters: 0.1, gutter-position: inside, use-custom: (box-sizing: false));
$splitgutter: (gutters: 0.4, gutter-position: split, use-custom: (box-sizing: false));
$minigutter: (gutters: 0.02, gutter-position: before, use-custom: (box-sizing: false));
$smallgutter: (gutters: 0.07, gutter-position: before, use-custom: (box-sizing: false));
$nogutter: (gutters: 0, use-custom: (box-sizing: false));

$black: #000;
$white: #FFF;
$lightgrey: #f3f3f4;
$grey: #818285;

$small: 600px;
$mid: 800px;
$medium: 1300px;
$large: 1600px;

$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutBounce: cubic-bezier(0.680, 0, 0.265, 1);



// rkt CSS util stuff
@mixin rkt($args...) {
	-content: "rkt #{$args}";
	&:before {
		content: "rkt #{$args}";
		width: 0;
		height: 0;
		position: absolute;
		overflow: hidden; } }

//page

.barba-container#entry {
	overflow-y: hidden; }

// .animate
// 	visibility: hidden

.row.animate {
	// visibility: hidden

	@include susy-breakpoint($small) {
		// opacity: 0
		transform: translateY(0%);
		display: block;
		// transition: all 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000)
		transition: all 1s ease;

		&.animatingFromTop {
			opacity: 1;
			transform: translateY(10vh); }

		&.animatingFromBottom {
			opacity: 1;
			transform: translateY(-10vh); } } }

article.barba-container.entry {
	position: relative;
	overflow-x: auto; }

h1 {
	position: relative;
	top: 0;
	left: 0;
	margin: 0;
	margin-bottom: 1rem;

	@include susy-breakpoint($medium) {
		position: fixed;
		top: 10rem;
		left: 2rem; } }

.content {
	@include susy-breakpoint($small) {
		background: $white;
		padding: 2rem 10rem 2rem 2rem; }

	p {
		margin-top: 0; }

	ul {
		list-style-type: disc;
		margin-left: 40px; } }

.page {
	@include container(100%);
	min-height: 100vh;
	// margin-bottom: 3rem
	margin-top: 1rem;
	padding: 1rem;
	padding-bottom: 0;

	@include susy-breakpoint($small) {
		padding: 2rem; }

	@include susy-breakpoint($medium) {
		margin-top: 5rem;
		margin-bottom: 5rem; }

	.row {
		width: 100%;
		position: relative;
		pointer-events: none;
		&::after {
			display: block;
			content: "";
			clear: both; }

		&.image {
			margin-bottom: 1rem;
			position: relative;
			@include susy-breakpoint($small) {
				margin-bottom: 2rem; }
			@include susy-breakpoint($medium) {
				margin-bottom: 0; }

			img {
				@include susy-breakpoint($mid) {
					display: inline-block;

					&.portrait {
						max-height: 70vh; } } } }

		&.video {
			margin-bottom: 1rem;
			@include susy-breakpoint($small) {
				margin-bottom: 2rem; }
			@include susy-breakpoint($medium) {
				margin-bottom: 0; }

			.video-trigger {
				display: block; }

			.video-play-btn {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 8rem;
				opacity: 0.5;
				z-index: 1;
				pointer-events: none;
				cursor: pointer; }

			.content.block {
				padding: 0; }

			video {
				display: block;
				width: 100%;
				pointer-events: none; } }

		&.text {
			margin-bottom: 1rem;
			z-index: 999;
			cursor: pointer;
			@include susy-breakpoint($small) {
				margin-bottom: 2rem; }

			@include susy-breakpoint($medium) {
				margin-bottom: 0; }

			p:last-child {
				margin-bottom: 0; } }

		&:first-child.text {
			margin-top: 2rem;
			@include susy-breakpoint($small) {
				margin-top: 2rem; }
			@include susy-breakpoint($medium) {
				margin-top: 10rem; }
			@include susy-breakpoint($large) {
				margin-top: 12rem; } }


		a {
			// display: block
			position: relative;
			z-index: 1; }

		.block {
			position: relative;
 } }			// z-index: 1

	.content,
	img {
		width: 100%;
		pointer-events: auto;
		@include susy-breakpoint($mid) {
			width: auto;
			max-width: 50%; } }

	h2 {
		margin-top: 0;
		font-size: 1.2rem; } }

.page-footer {
	display: block;
	padding: 1rem;
	padding-top: 0;
	background: $lightgrey;
	position: relative;

	@include container(100%);
	width: 100%;
	max-width: 100%;

	@include susy-breakpoint($small) {
		padding: 2rem; }

	@include susy-breakpoint($large) {
		display: flex; }

	h4 {
		margin: 0.5rem 0 1rem;
		@include susy-breakpoint($small) {
			margin: 0.5rem 0 2rem; } }

	.related {
		// margin-top: 3rem
		border-top: 1px solid;
		position: relative;
		z-index: 99;

		.related-header {

			overflow: auto;

			h4 {
				float: left; } }

		.share.related-arrows {
			margin-top: 10px;
			margin-bottom: 10px;
			width: auto;
			float: right;
			display: none;

			&.more {
				display: block; }

			.right {
				margin-left: 5px; }

			.left, .right {
				cursor: pointer;
				display: inline-block;

				i {
					font-size: 2rem; } }

			&.count-3 {
				// as defined in slick JS breakpoint
				@include susy-breakpoint(1024px) {
					display: none; } } }

		.related-items {
			clear: both;
			overflow-x: hidden;

			@include susy-breakpoint($small) {
				.slick-list {
					margin-left: -20px; }
				.slick-slide {
					margin-left: 10px; } } }

		@include susy-breakpoint($small) {
			display: inline-block;
			@include span(4 of 4 $nogutter);
			float: left;
			display: block;
 }			// margin-top: 1em

		@include susy-breakpoint($large) {
			// margin-top: 6rem
			@include span(3 of 4 $nogutter); }

		&-item {
			margin-bottom: 1rem;
			position: relative;

			@include susy-breakpoint($small) {
				margin-bottom: 0;
				display: inline-block; }

			@include susy-breakpoint($small) {
				@include span(1 of 3 $smallgutter);

				&:nth-child(2) {
					@include span(1 of 3 $smallgutter first); }

				&:nth-child(3) {
					@include span(1 of 3 $smallgutter); }

				&:nth-child(4) {
					@include span(1 of 3 $smallgutter last); } }

			.title {
				z-index: 3;
				position: absolute;
				top: 0.5rem;
				left: 0.5rem;
				font-size: 1rem;
				color: $white;
				transition: opacity 0.3s ease;
				opacity: 0;
				// transform: translateZ(1000px)
				display: block;
				text-align: left;
				width: 80%;
				@include susy-breakpoint($medium) {
					font-size: 1.5rem;
					top: 0.5rem;
					left: 1rem; } }

			&[data-slick-index="1"] {
				.title {
					left: 1rem;
					@include susy-breakpoint($medium) {
						left: 1.5rem; } } }

			.item-bg {
				z-index: 2;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: block;
				opacity: 0;
				transition: opacity 0.3s ease;
				background: $black; }

			&:hover {
				span {
					opacity: 1; }

				.item-bg {
					opacity: 0.5; } }

			a {
				display: block; } } }

	.share {
		// margin-top: 1rem
		position: relative;
		z-index: 99;
		@include span(4 of 4 $nogutter);

		@include susy-breakpoint($small) {
			margin-top: 2rem; }

		@include susy-breakpoint($large) {
			display: inline-block;
			float: left;
			display: block;
			margin-top: 0rem;

			&.has-related,
			&.has-sector, {
				@include span(1 of 4 $nogutter); } }

		&-inner {
			border-top: 1px solid; }

		&.has-related,
		&.has-sector {
			@include susy-breakpoint($large) {
				.share-inner {
					margin-left: 2rem;
					min-height: 250px; } } }

		&-icons {
			margin: 0;
			padding: 0;
			float: left;

			li {
				display: inline-block;
				margin-right: 0.5rem;
				margin-bottom: 0.5rem;
				float: left; } }

		a {
			display: block;
			font-size: 1.2rem;
			border: 2px solid $black;
			border-radius: 50%;
			width: 2.3rem;
			height: 2.3rem;
			text-align: center;
			line-height: 2.2rem; }

		i.material-icons {
			line-height: 2.2rem;
			vertical-align: top; }


		.back-to-top {
			cursor: pointer;
			float: right;

			@include susy-breakpoint($large) {
				float: right; }

			i {
				font-size: 2rem; } }

		&.has-related,
		&.has-sector {
			@include susy-breakpoint($large) {
				.back-to-top {
					position: absolute;
					left: 2rem;
					right: auto;
					bottom: 0; } } } } }

// .page#scene.history
// 	h2
// 		font-size: 2.5rem
// 		margin-bottom: 1rem

// ** Disable for testing **
// #preload-overlay
// 	display: none

#loading {
	display: none;
	&.show {
		display: flex;
		align-content: center;
		flex-direction: column;
		justify-content: center;
		pointer-events: none;
		text-align: center;
		font-weight: bold;
		font-family: 'Arial';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba($white, 0.9);
		z-index: 9999999; } }

#grid {
	h1 {
		padding: 0 1rem; } }

.grid-wrap {

	padding: 1rem;

	&:not(.mobile) {
		perspective: 1000px;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		pointer-events: none;

		.grid-container {
			position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			width: 100vw;
			@include container(100%);
			text-align: center;
			font-size: 0;
			// transform-style: preserve-3d    ** Enabling this is causing weird glitchiness
			pointer-events: none; } }

	&.mobile .item {
		-webkit-backface-visibility: hidden;
		transition: opacity 0.3s ease 1s;
		opacity: 0;

		&.show {
			opacity: 1; } }

	.item {
		// background: $lightgrey
		cursor: pointer;
		pointer-events: all;
		margin: 1rem 0;
		@include span(1 of 1 $nogutter);
		display: block;
		float: none;
		position: relative;
		transition: opacity 1s ease;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		-webkit-backface-visibility: hidden;
		transform-origin: center;
		opacity: 1;
		z-index: 201;
		// outline: 1px solid transparent
		&:hover {
			z-index: 9999999 !important; }

		@include susy-breakpoint($small) {
			margin: 4rem 0;
			@include span(1 of 3 $nogutter);
			float: none;
			margin: 2rem 0;
			display: inline-block;
			vertical-align: middle; }

		@include susy-breakpoint($medium) {
			margin: 6rem 0;
			@include span(1 of 4 $nogutter);
			float: none; }

		&.inactive {
			// transition: opacity 0.3s ease 0.5s
			// filter: blur(2px)

			img {
				opacity: 0.5;
 } }				// filter: blur(2px)

		a {
			display: block;
			position: relative;
			// padding: 1rem


			@include susy-breakpoint($small) {
				&.portrait {
					width: 80%;
					span {
						width: 70%; } } } }


		img {
			z-index: 1;
			display: block;
			width: 100%;
			transition: opacity 0.5s ease 0s;
 }			// outline: 1px solid transparent

		.item-bg {
			z-index: 2;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			opacity: 0;
			transition: opacity 0.3s ease;
			background: $black; }

		.title {
			z-index: 3;
			position: absolute;
			top: 0.5rem;
			left: 0.5rem;
			font-size: 1rem;
			color: $white;
			transition: opacity 0.3s ease;
			opacity: 0;
			// transform: translateZ(1000px)
			display: block;
			text-align: left;
			width: 80%;
			@include susy-breakpoint($medium) {
				font-size: 1.5rem;
				top: 0.5rem;
				left: 1rem; } }

		// &.light,
		&.people {
			.title {
				// color: $black

				span {
					display: block;
					font-size: 1rem; } } }

		&.hover, &.hovered, &:hover, &:focus {
			img {
				cursor: grab; }

			.item-bg {
				opacity: 0.5; }

			&:active img {
				cursor: grabbing; }
			.title {
				opacity: 1;
				cursor: pointer; } } }

	&.people {
		.item {
			@include span(1 of 2 $nogutter);
			@include susy-breakpoint($small) {
				margin: 4rem 0;
				@include span(1 of 4 $nogutter);
				float: none; }

			@include susy-breakpoint($medium) {
				margin: 6rem 0;
				@include span(1 of 6 $nogutter);
				float: none; } } } }

//base html stuff
html,
body {
	height: 100%;
	min-height: 100%;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	background: $lightgrey;
	color: $black;
	font-family: sans-serif;
	font-size: 100%;
	font-family: 'Arial';

	@include susy-breakpoint($small) {
		font-size: 100%; }

	@include susy-breakpoint($medium) {
		font-size: 110%; } }

html {
	&.no-scroll {
		overflow: hidden; } }

body {
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch; }

.bg {
	position: fixed;
	width: 300px;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index: 999999999;
	background: $lightgrey;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 75%;
	@include susy-breakpoint($small) {
		background-size: auto;
		width: 600px; }

	&.done {
		z-index: 0; }

	img {
		display: none;
		position: absolute;
		width: 300px;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 1;
		@include susy-breakpoint($small) {
			width: 600px; }

		&.logo {
			display: block;
			z-index: 2;
			opacity: 0; } } }

* {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	box-sizing: border-box;
	&:focus {
		outline: none; } }

.clearfix:after {
	content: "";
	display: table;
	clear: both; }

img {
	display: block;
	width: 100%; }

a {
	text-decoration: none;
	color: $black; }

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;

	li {
		padding: 0; } }

input,
button {
	border-radius: 0; }

#barba-wrapper {
	position: relative;
	transition: opacity 1s ease;

	&.inactive {
		opacity: 0.25 !important; } }

.barba-container {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 100vw;
	width: 100%;
	overflow-x: hidden;
	min-height: 100vh;
	-webkit-overflow-scrolling: touch;
	z-index: 2;
	padding-top: 4rem;

	@include susy-breakpoint($small) {
		padding-top: 0;
		padding-top: 5rem;
		min-height: 100vh; }

	&#index {
		padding-top: 4rem;
		@include susy-breakpoint($small) {
			padding-top: 10rem; }
		@include susy-breakpoint($large) {
			padding-top: 20rem; } } }

#preload-bar {
	width: 100%;
	height: 5px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999999999;
	background: $lightgrey;

	svg {
		height: 5px; }

	path {
		stroke: $grey;
		opacity: 0.25; } }

#preload-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99999999;
	background-color: $lightgrey; }

.icon-scroll {
	position: fixed;
	bottom: 1rem;
	right: 50%;
	z-index: 400;
	width: 20px;
	height: 40px;
	margin-left: -20px;
	margin-top: -35px;
	box-shadow: inset 0 0 0 2px #000000;
	border-radius: 20px;
	display: none; }

.icon-scroll:before {
	position: absolute;
	left: 50%;
	content: '';
	width: 8px;
	height: 8px;
	background: #000000;
	margin-left: -4px;
	top: 7px;
	border-radius: 4px;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-name: scroll; }

@keyframes scroll {
	0% {
		opacity: 1; }
	100% {
		opacity: 0;
		transform: translateY(20px); } }

// Magnific Popup

.mfp-wrap {
	z-index: 999043;
	transform: translateZ(99999px); }

.mfp-figure {
	position: relative;
	&:after {
		box-shadow: none;
		background: transparent; } }

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: pointer; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	width: auto; }


img.mfp-img {
	padding: 0; }


.mfp-close,
.mfp-arrow-left,
.mfp-arrow-right {
	position: fixed;
	opacity: 1;
	padding: 0;
	padding-right: 0;
	font-size: 2rem;
	font-family: 'Material Icons';
	color: $white;
	line-height: normal;
	height: auto;
	width: auto;
	z-index: 9999;
	cursor: pointer; }

.mfp-close,
.mfp-close:active {
	right: 0.5rem;
	top: 0.5rem;
	@include susy-breakpoint($small) {
		right: 1rem;
		top: 1rem; } }

.mfp-arrow-left {
	left: 0.5rem;
	top: 50%;
	font-size: 3rem;
	@include susy-breakpoint($small) {
		left: 1rem; } }

.mfp-arrow-right {
	right: 0.5rem;
	top: 50%;
	font-size: 3rem;
	@include susy-breakpoint($small) {
		right: 1rem; } }

.mfp-fade.mfp-bg {
	opacity: 0;
	transition: all 0.15s ease-out; }

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.8; }

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0; }

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	transition: all 0.15s ease-out; }

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1; }

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0; }


.mfp-counter {
	display: none; }


.video-popup {
	video {
		display: block;
		margin: 0 auto;
		width: 100%; } }

// Slick Slider Overrides

.slick-track {
	margin-left: 0;
	margin-right: 0; }

.flex {
	display: flex;
	flex-wrap: wrap;
	list-style: none;

	.cell {
		flex: 1; }

	.cell-wider {
		flex: 1.5; }

	.cell-thinner {
		flex: 0.5; }

	.pad-right {
		padding-right: 0.5rem; } }

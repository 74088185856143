//typography

h1 {
	font-size: 2rem;
	font-weight: normal;
	top: 4rem;
	z-index: 999;

	@include susy-breakpoint($small) {
		font-size: 3.5rem;
		position: fixed;
		max-width: 80%; }

	@include susy-breakpoint($medium) {
		max-width: 50%; }

	@include susy-breakpoint($large) {
		font-size: 5rem; } }

.page.layout-one {
	position: relative;

	@include susy-breakpoint($mid) {

		// HERO IMAGE
		.item-0 .draggable {
			margin-top: 0;
			margin-right: 5%;
			float: right; }

		// FIRST TEXT BOX
		.item-1 {
			.draggable {
				margin-right: -2rem; }
			&.landscape .draggable {
				margin-top: -5%; }
			&.portrait .draggable {
				margin-top: -25%; } }


		.item-2 .draggable {
			margin-left: 15%;
			margin-top: 5%;
			&:not(.portrait):not(.content) {
				width: 35%; } }

		.item-3 .draggable {
			float: right;
			margin-top: 0%;
			margin-right: 5%; }

		.item-4 .draggable {
			margin-top: -2%;
			margin-left: 10%;
			&:not(.portrait):not(.content) {
				width: 30%; } }

		.item-5 .draggable {
			float: right;
			margin-right: 10%;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-6 .draggable {
			margin-left: -2rem;
			margin-top: 5%; }

		.item-7 .draggable {
			float: right;
			margin-right: 20%;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-8 .draggable {
			float: right;
			margin-right: -2rem;
			margin-top: 5%;
			z-index: 1;
			position: relative;
			&:not(.portrait):not(.content) {
				width: 50%; } }

		.item-9 .draggable {
			margin-left: 5%;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 50%; } }

		.item-10 .draggable {
			float: right;
			margin-right: 5%;
			margin-top: -5%; }

		.item-11 .draggable {
			margin-left: 30%;
			margin-top: 2%; }

		.item-12 .draggable {
			margin-top: -5%;
			margin-left: 5%;
			&:not(.portrait):not(.content) {
				width: 60%; } }

		.item-13 .draggable {
			float: right;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-14 .draggable {
			float: right;
			margin-right: 10%;
			margin-top: 3%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-15 .draggable {
			margin-left: 10%;
			margin-top: 5%;
			&:not(.portrait):not(.content) {
				width: 35%; } }

		.item-16 .draggable {
			float: right;
			margin-right: 5%;
			margin-top: -5%; }

		.item-17 .draggable {
			margin-left: 30%;
			margin-top: 2%; }

		.item-18 .draggable {
			margin-top: -5%;
			margin-left: 5%;
			&:not(.portrait):not(.content) {
				width: 60%; } }

		.item-19 .draggable {
			float: right;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-20 .draggable {
			float: right;
			margin-right: 10%;
			margin-top: 3%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-22 .draggable {
			margin-left: 10%;
			margin-top: 5%;
			&:not(.portrait):not(.content) {
				width: 35%; } }

		.item-23 .draggable {
			float: right;
			margin-right: 5%;
			margin-top: -5%; }

		.item-24 .draggable {
			margin-left: 30%;
			margin-top: 2%; }

		.item-25 .draggable {
			margin-top: -5%;
			margin-left: 5%;
			&:not(.portrait):not(.content) {
				width: 60%; } }

		.item-26 .draggable {
			float: right;
			margin-top: -10%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-27 .draggable {
			float: right;
			margin-right: 10%;
			margin-top: 3%;
			&:not(.portrait):not(.content) {
				width: 40%; } }

		.item-28 .draggable {
			margin-left: 10%;
			margin-top: 5%;
			&:not(.portrait):not(.content) {
				width: 35%; } } } }

#search-results {
	padding-top: 20rem;

	.index-image {
		img {
			width: auto;
			max-width: 100%;
			max-height: 400px; } } }

.search {
	@include container(1000px);
	background: $white;
	position: fixed;
	padding: 1rem 1rem;
	z-index: 9999999;
	width: 100%;
	display: none;

	@include susy-breakpoint($small) {
		width: 90%;
		padding: 1rem 1.5rem; }

	&.active {
		display: block;

		&::after {
			content: "";
			position: fixed;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: $white;
			z-index: -1;

			@include susy-breakpoint($small) {
				display: none; } } }

	input {
		border: none;
		border-bottom: 1px solid $black;
		width: 100%;
		font-size: 2rem;
		padding: 1rem 0;
		border-radius: 0;
		@include susy-breakpoint($small) {
			font-size: 3rem; } }

	button {
		display: block;
		background: none;
		color: $black;
		border: none;
		padding: 0;
		font-size: 1.5rem;
		font-family: 'Arial';
		cursor: pointer; }

	ul.suggestions {
		max-height: 250px;
		overflow-y: auto;

		li {
			&:first-child {
				margin-top: 1rem; }
			&:hover,
			&.selected {
				background: rgba($black,0.05); } }

		a {
			display: block;
			padding: 0.15rem 0; } } }

// Index page
.index {
	padding: 1rem;
	@include susy-breakpoint($small) {
		padding: 2rem; }
	@include container(100%);
	ul.index-list {
		margin-top: 2rem;
		@include span(1 of 1);

		@include susy-breakpoint($medium) {
			margin-top: 0;
			@include span(1 of 2);
			float: right;
			background: $white;
			padding: 1rem; }

		li {
			border-bottom: 1px solid rgba($black, 0.1);
			padding: 0.25rem 0;

			&:first-child {
				border-top: 1px solid rgba($black, 0.1); }

			&.sort {
				border-top: none;
				border-bottom: 1px solid rgba($black, 0.8); }

			.image-link {
				display: none; }

			.sort-title {
				font-family: 'Arial';
				font-weight: bold;
				font-size: 0.8rem; }

			.sort-title.unsorted {
				color: rgba($black, 0.3); } }


		a {
			display: block; }

		.role,
		.collection {
			float: right;
			font-size: 1rem;
			padding-top: 0.25rem; } }

	.index-image {
		position: fixed;
		bottom: 1rem;
		left: 1rem;
		display: none;

		a {
			display: block; }

		@include susy-breakpoint($medium) {
			display: block;
			background: $grey;
			min-height: 200px;
			max-width: 500px;
			bottom: 2rem;
			left: 2rem; }

		a.portrait {
			min-height: 200px;
			max-width: 300px; }

		a.landscape {
			min-height: 200px;
			max-width: 500px; } } }

.page.people {
	// min-height: auto

	.item-0 .block {
		max-width: 600px;
		@include susy-breakpoint($medium) {
			float: right;
			max-width: 30%;
			margin-top: 0%;
			margin-right: 15%; } }


	.item-1 .content {
		@include susy-breakpoint($medium) {
			margin-top: -20%;
			margin-left: 5%; } } }
